<template>
	<section class="video_box">
		<div class="for_desktop" v-if="!props.isMobile">
			<video @contextmenu.prevent="" controlsList="nodownload" class="videodiv" loop muted autoplay playsinline width="3840" height="1520">
				<source src="https://assets.friendlydiamonds.com/fdl/homepagebanner/valentine_desktop.mp4?252222" type="video/mp4">
			</video>
		</div>
		<div v-else class="for_mobile">
			<video @contextmenu.prevent="" controlsList="nodownload" class="videodiv" loop muted autoplay playsinline width="1380" height="1920">
				<source src="https://assets.friendlydiamonds.com/fdl/homepagebanner/valentine_mobile.mp4?252222" type="video/mp4">
			</video>
		</div>

		<div class="upper_text">
			<!-- <span>SHINE YOUR WAY INTO THE NEW YEAR</span> -->
			<!-- <p class="mb-3 cap_v">So this is love</p> -->
			<p class="mb-3 cap_v"> Indulge in Love </p>
			<!-- <h3 class="font-active mb-3">Up to 50% off</h3> -->
			<h3 class="font-active">
				ENGAGEMENT RINGS AT 40% OFF

			</h3>
			<!-- <p class="mb-4 cap_text">This valentine’s day, celebrate with a gift that lasts forever.</p> -->
			<div class="anker_flex">
				<!-- <router-link :to="localePath('/engagement-rings')">SHOP ENGAGEMENT RINGS</router-link> -->
				<router-link class="btn-p-animation" :to="localePath({ name:'cyo-setting-list-ring'})">
					<span>Create Your Ring</span>
				</router-link>
				<!-- <router-link :to="localePath('/fine-jewelry')">Ready to Ship JEWELRY</router-link> -->
				<router-link class="btn-p-animation" :to="localePath({ name: 'preset-listing', 'RefreshKey': 'all'})">
					<span>SHOP PRE-DESIGNED RINGS</span>
				</router-link>
			</div>
		</div>

		<p class="terms-and-condition" @click="openBFModal">
			<span >*T&C &nbsp;APPLIED</span>
		</p>

		<CommonCustomModal v-model="flagShowModal" :size="'sm'" :no_padd="false">
			<ModalTermsAndConditionsBF />
        </CommonCustomModal>
		
		
	</section>
</template>

<script setup>
const localePath = useLocalePath()
const props = defineProps(['isMobile'])

const flagShowModal = ref(false);
	const openBFModal = () => {
        flagShowModal.value = true;
    };

</script>

<style scoped>
.video_box{
	position: relative;
}
.video_box:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-image: linear-gradient(to bottom, rgba(196, 196, 196, 0) 60%, #00000080 100%);
}
video{
	max-width: 100%;
	height: auto;
	display: block;
}
.upper_text {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
	width: 100%;
}

.upper_text span {
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
}

.upper_text h3 {
    font-size: 48px;
    color: #fff;
	letter-spacing: 0;
	/* text-transform: uppercase; */
	font-weight: 400;
	text-align: center;
	margin-bottom: 45px;

}

.upper_text h3::after {
	/* content: '*';  */
	font-size: 20px; 
	color: #fff; 
	margin-left: 4px; 
	vertical-align: top; 
}



.upper_text {
    color: #fff;
    font-size: 16px;
   
    letter-spacing: 2px;
}
.upper_text .cap_v{
	font-weight: 500;
	text-transform: uppercase;
	font-size: 18px;
	letter-spacing: 2.5px;
}


.upper_text .cap_text{
	letter-spacing: 0.5px;
}
.anker_flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.anker_flex a {
	border: 1px solid #fff;
	letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 4px;
    margin: 0 10px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
}

.anker_flex a span{
	font-size: 14px;
	color: #fff;
}


.terms-and-condition{
	font-size: 10px;
	position: absolute;
	bottom: 10px;
	right: 15px;
	text-transform: uppercase;
	margin: 0;
	cursor: pointer;
	z-index: 2;
	color: #ffffff;
	text-decoration: underline;
}
@media (max-width:1024px) {
	.upper_text h3 {
		font-size: 40px;
	}
	.upper_text span, .upper_text p {
		font-size: 16px;
	}
}
@media (max-width:767px) {
	.upper_text h3::after{
		color: #fff;
	}
	.video_box:before {
		background-image: linear-gradient(to bottom, rgba(196, 196, 196, 0) 60%, #000000ad 100%);
		height: 100%;
	}
	.upper_text h3, .upper_text span, .upper_text p {
		color: #fff;
	}
	
	.video_box {
		position: relative;
		/* min-height: 60vh; */
	}
	.upper_text h3 {
		font-size: 28px;
		margin-bottom: 24px;
	}
	.upper_text span, .upper_text p {
		font-size: 14px;
	}
	.upper_text{
		width: 90%;
	}
	.anker_flex {
		flex-wrap: wrap;
	}
	.anker_flex a {
		width: 100%;
		margin-bottom: 10px;
		border: 1px solid #fff;
		display: flex;
	}
	.upper_text .cap_v {
		font-size: 14px;
		letter-spacing: 1.5px;
	}
}

</style>